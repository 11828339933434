import "core-js/modules/es.number.constructor.js";
import { ValidationProvider } from "vee-validate";
export default defineComponent({
  name: "SelectWithValidate",
  components: {
    ValidationProvider: ValidationProvider
  },
  props: {
    value: {
      type: [String, Array, Number],
      required: true
    },
    rules: {
      type: String,
      required: false,
      default: ""
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    multi: {
      type: Boolean,
      default: false
    }
  }
});